import React from 'react'

import ContentContainer from '../components/ContentContainer/ContentContainer'

export default function IndustriesView() {
  const content = (
    <div>
      <p>Our solutions apply to many industries such as:</p>
      <ul>
        <li>Software and Technology</li>
        <li>Industrial Manufacturing</li>
        <li>Retail</li>
        <li>Consumer Electronics</li>
        <li>Office Automation</li>
      </ul>
      <p>Contact us for your needs and let s talk!</p>
    </div>
  )

  const img = <img src='https://imgur.com/0qp8bqA.jpg' style={{width: '100%'}} alt='A person, who types on laptop keyboard' className='img-fluid'></img>

  const contentData = [
    {
      columnData: {
        colWidth: 12,
        colHeader: 'Industries'
      }
    },
    {
      columnData: [
        {
          colWidth: 7,
          img,
          layoutData: {
            crossAxisLayout: 'start'
          }
        },
        {
          colWidth: 5,
          colContent: content,
        },

      ],

    }
  ] 

  return <ContentContainer contentData={contentData} />
}
