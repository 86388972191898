import React, {useState} from 'react'
import axios from 'axios';
import {TOAST_SEVERITY, TOAST_MESSAGE} from '../utils/constants';

import ContentContainer from '../components/ContentContainer/ContentContainer'
import Spinner from '../components/Spinner/Spinner';
import CustomTextField from '../components/CustomTextField/CustomTextField'
import CustomButton from '../components/CustomButton/CustomButton'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Map from '../components/Map/Map';
import Toast from '../components/Toast/Toast';

const SEND_EMAIL_ENDPOINT = 'https://constant-tracker-backend.herokuapp.com/contact';
const FIRST_NAME_ERROR_MSG = 'Please type your first name.';
const LAST_NAME_ERROR_MSG = 'Please type your last name.';
const MESSAGE_ERROR_MSG = 'Please type the message, which you want to leave us.';
const EMAIL_MSG = {
  REQUIRED: 'Please type your E-mail.',
  INVALID: 'Please type a correct E-mail address.'
};

export default function ContactView() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isClearForm, setIsClearForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [toastData, setToastData] = useState({
    message: '',
    open: false,
    severity: null
  });
  const [validationError = { firstName, lastName, email, message },
    setValidationError] = useState({
      firstName: null,
      lastName: null, 
      email: null, 
      message: null
    });

  const validateForm = () => {
    const validationErrorArray = Object.values(validationError);
    const errors = validationErrorArray.filter(error => error);
    if (isClearForm) {
      return false;
    }
    if (errors.length) {
      return false;
    }
    return true;
  };

  const resetForm = () => {
    setIsClearForm(true);
    setFirstName('');
    setLastName('');
    setEmail('');
    setMessage('');
  };

  const handleError = (error) => {
    console.log(error);
    setToastData({
      message: TOAST_MESSAGE.ERROR,
      open: true,
      severity: TOAST_SEVERITY.ERROR
    });
    setIsLoading(false);
  };

  const handleForm = async (messageToSend) => {
    setIsLoading(true);
    const response = await axios.post(SEND_EMAIL_ENDPOINT, messageToSend);
    if (response.status === 200) {
      setToastData({
        message: TOAST_MESSAGE.SENT,
        open: true,
        severity: TOAST_SEVERITY.SUCCESS
      })
      setIsLoading(false);
      resetForm();
    }
  };

  const getMessageToSend = () => {
    return {
      firstName,
      lastName,
      email,
      message
    };
  };

  const sendMessage = async () => {
    const isFormValid = validateForm();
    if (isClearForm && !isFormValid) {
      setValidationError({
        firstName: FIRST_NAME_ERROR_MSG,
        lastName: LAST_NAME_ERROR_MSG, 
        email: EMAIL_MSG.REQUIRED, 
        message: MESSAGE_ERROR_MSG
      });
    } if (isFormValid) {
      try {
        const messageToSend = getMessageToSend();
        await handleForm(messageToSend);
      } catch(error) {
        handleError(error);
      }
    }
  };

  const validateRequired = (fieldName, fieldValue, errorMessage) => {
    let newValidationError = {...validationError};

    if (fieldValue && validationError[fieldName]) {
      newValidationError[fieldName] = null;
      setValidationError(newValidationError);
    }
    if (!fieldValue && !validationError[fieldName]) {
      newValidationError[fieldName] = errorMessage;
      setValidationError(newValidationError);
    }
  }

  const validateEmail = (emailValue) => {
    validateRequired('email', emailValue, EMAIL_MSG.REQUIRED);
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailValue)) {
      setValidationError({...validationError, email: EMAIL_MSG.INVALID});
      return;
    }
  };
  
  const validateFirstName = (firstNameValue) => {
    validateRequired('firstName', firstNameValue, FIRST_NAME_ERROR_MSG);
  };

  const validatelastName = (lastNameValue) => {
    validateRequired('lastName', lastNameValue, LAST_NAME_ERROR_MSG);
  }

  const validateMessage = (messageValue) => {
    validateRequired('message', messageValue, MESSAGE_ERROR_MSG);
  };

  const onChangeFirstName = (value) => {
    if (validationError.firstName) {
      validateFirstName(value);
    }
    setFirstName(value);
    setIsClearForm(false);
  };

  const onChangeLastName = (value) => {
    if (validationError.lastName) {
      validatelastName(value);
      setIsClearForm(false);
    }
    setLastName(value);
  };

  const onChangeEmail = (value) => {
    if (validationError.email) {
      validateEmail(value);
    }
    setEmail(value);
    setIsClearForm(false);
  };

  const onChangeMessage = (value) => {
    if (validationError.message) {
      validateMessage(value);
    }
    setMessage(value);
    setIsClearForm(false);
  };

  const setOpenToast = (newState) => {
    setToastData({...toastData, open: newState})
  }

  const onCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const renderToast = () => {
    const {message, open, severity} = toastData;
    const toastProps = {
      message,
      open,
      severity,
      onClose: onCloseToast
    };

    return <Toast {...toastProps}/>;
  };

  const firstNameContent = (
    <CustomTextField 
    value={firstName}
    label='First Name'
    error={Boolean(validationError.firstName)}
    helperText={validationError.firstName}
    onChange={(e) => onChangeFirstName(e.target.value)} 
    onBlur={(e) => validateFirstName(e.target.value)}/>
  );

  const lastNameContent = (
    <CustomTextField
    value={lastName}
    label='Last Name'
    error={Boolean(validationError.lastName)}
    helperText={validationError.lastName}
    onChange={(e) => onChangeLastName(e.target.value)}
    onBlur={(e) => validatelastName(e.target.value)}/>
  );

  const flexCenteredStyle = {
    display: 'flex',
    justifyContent: 'center'
  };

  const nameInputRowStyle = {
    ...flexCenteredStyle,
    marginBottom: '5px'
  };

  const emailContent = <CustomTextField
  value={email}
  label='Email'
  error={Boolean(validationError.email)}
  helperText={validationError.email}
  onChange={(e) => onChangeEmail(e.target.value)} 
  onBlur={(e) => validateEmail(e.target.value)}/>;

  const messageContent = <CustomTextField
  value={message}
  label='Message'
  multiline
  rows={4}
  variant='outlined'
  error={Boolean(validationError.message)}
  helperText={validationError.message}
  onChange={(e) => onChangeMessage(e.target.value)}
  onBlur={(e) => validateMessage(e.target.value)}
/>;

const renderButtonContent = () => {
  if (isLoading) {
    return <Spinner/>
  }
  return (<CustomButton
  variant='contained' 
  color='primary' 
  fullWidth 
  onClick={sendMessage}>
   Send Message
 </CustomButton>);
}

const dividerContent = <hr/>

const contactInfoLineStyle = {
  display: 'flex',
  marginBottom: '20px'
};

const contactInfoElementStyle = {
  paddingLeft: '15px'
};

const contactInfoContent = (
  <div>
    <div style={contactInfoLineStyle}>
      <LocationOnIcon/>
      <div style={contactInfoElementStyle}>
        <div>
          <p>98 Cutter Mill Rd.</p>
        </div>
        <div>
          <p>Suite 300A North</p>
        </div>
        <div>
          <p>Great Neck, NY  11021</p>
        </div>
      </div>
    </div>
    <div style={contactInfoLineStyle}>
      <PhoneIcon/>
      <div style={contactInfoElementStyle}>
        <p>212-398-9085</p>
      </div>
    </div>
    <div style={contactInfoLineStyle}>
      <EmailIcon/>
      <div style={contactInfoElementStyle}>
        <p>info@constant-tracker.com</p>
      </div>
    </div>
  </div>
);
const mapProps = {
  lat: 40.78364,
  lng: -73.73243,
  zoom: 13
};

const mapContent = <Map {...mapProps}/>

  const contentData = [
    {
      rowGap: 20,
      customRowStyles: flexCenteredStyle,
      columnData: 
        {
          colWidth: 12,
          colHeader: 'Contact'
        }
    },
    {
      rowGap: 20,
      customRowStyles: nameInputRowStyle,
      columnData: [
        {
          colWidth: 3,
          colHeader: '',
          colContent: firstNameContent,
          layoutData: {
            mainAxisLayout: 'center'
          }
        },
        {
          colWidth: 3,
          colContent: lastNameContent,
          layoutData: {
            mainAxisLayout: 'center'
          }
        }
      ]
    },
    {
      rowGap: 20,
      customRowStyles: flexCenteredStyle,
      columnData: [
        {
          colWidth: 6,
          colContent: emailContent,
          layoutData: {
            mainAxisLayout: 'center'
          }
        }
      ],
    },
    {
      rowGap: 20,
      customRowStyles: flexCenteredStyle,
      columnData: [
        {
          colWidth: 6,
          colContent: messageContent,
          layoutData: {
            mainAxisLayout: 'center'
          }
        }
      ]
    },
    {
      rowGap: 20,
      customRowStyles: flexCenteredStyle,
      columnData: [
        {
          colWidth: 6,
          colContent: renderButtonContent(),
          layoutData: {
            mainAxisLayout: 'center'
          }
        }
      ]
    },
    {
      rowGap: 20,
      customRowStyles: flexCenteredStyle,
      columnData: [
        {
          colWidth: 6,
          colContent: dividerContent,
          layoutData: {
            mainAxisLayout: 'center'
          }
        }
      ]
    },
    {
      customRowStyles: flexCenteredStyle,
      columnData: [
        {
          colWidth: 6,
          colContent: contactInfoContent,
          layoutData: {
            mainAxisLayout: 'start'
          }
        }
      ],
    },
    {
      customRowStyles: flexCenteredStyle,
      columnData: [
        {
          colWidth: 6,
          colContent: mapContent,
          layoutData: {
            mainAxisLayout: 'center'
          }
        }
      ],
    }
  ]

  return (
    <>
      {renderToast()}
      <ContentContainer contentData={contentData} />
    </>
  )
}
