import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

export default function Map({lat, lng, zoom}) {
  return (
  <MapContainer center={[lat, lng]} zoom={zoom} scrollWheelZoom={true}>
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <Marker position={[lat, lng]}>
      <Popup>
        A pretty CSS3 popup. <br /> Easily customizable.
      </Popup>
    </Marker>
  </MapContainer>
  );
}