export const getFlexLayoutClasses = (layoutData) => {
  const {mainAxisLayout, crossAxisLayout} = layoutData;
  let classNames = ''
  if (mainAxisLayout || crossAxisLayout) {
    classNames = 'd-flex';
  }

  if (mainAxisLayout) {
    if (mainAxisLayout === 'start') {
      classNames = classNames.concat(' justify-content-start')
    }
    if (mainAxisLayout === 'center') {
      classNames = classNames.concat(' justify-content-center')
    }
    if (mainAxisLayout === 'end') {
      classNames = classNames.concat(' justify-content-end')
    }
  }

  if (crossAxisLayout) {
    if (crossAxisLayout === 'start') {
      classNames = classNames.concat(' align-items-start')
    }
    if (crossAxisLayout === 'center') {
      classNames = classNames.concat(' align-items-center')
    }
    if (crossAxisLayout === 'end') {
      classNames = classNames.concat(' align-items-end')
    }
  }

  return classNames;
}
