import React from 'react'

import ContentContainer from '../components/ContentContainer/ContentContainer'

export default function SericesView() {
  const content = (
    <>
    <h3>Embedded Systems Development</h3>
      <p>
              Our engineers can design complex
        embedded systems that combine powerful
        hardware and custom software using state
        of the art technologies. We cover the
        entire design cycle from concept and
        strategy design, system development,
        code generation and integration,
        implementation and testing. We have
        expertise in Windows based embedded
        design, SQL® Databases, .NET® platforms
        and C#.
      </p>
    </>
  )

  const img = <img src='https://imgur.com/N9DJncY.jpg' style={{width: '100%'}} alt='IOT' className='img-fluid'></img>

  const contentData = [
    {
      columnData: {
        colWidth: 12,
        colHeader: 'Services'
      }
    },
    {
      columnData: [
        {
          colWidth: 5,
          colContent: content
        },
        {
          colWidth: 6,
          img,
          layoutData: {
            crossAxisLayout: 'center'
          }
        }
      ],

    }
  ] 

  return <ContentContainer contentData={contentData} />
}
