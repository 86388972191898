import './App.scss';
import {
  BrowserRouter as Router,
} from 'react-router-dom';

import Switcher from './components/Switcher/Switcher';
import Navbar from './components/Navbar/Navbar';
import Footer from '../src/components/Footer/Footer'

function App() {
  return (
    <Router>
        <div className='App'>
        <Navbar/>
          <Switcher/>
          <Footer/>
        </div>
    </Router>
  );
}

export default App;
