import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import { withResizeDetector } from 'react-resize-detector';
import cx from 'classnames';
import {NavLink} from 'react-router-dom';

import './Navbar.scss'
import {SMALL_MODE_WIDTH_BREAKPOINT} from '../../utils/constants'
import Logo from '../Logo/Logo';

const navLinksList = [
  {url: '/services', title: 'Services'},
  {url: '/products', title: 'Products'},
  {url: '/industries', title: 'Industries'},
  {url: '/about', title: 'About'}
]

const Navbar = ({width}) => {
  const location = useLocation();

  const [isSmall, setIsSmall] = useState(false);

  useEffect(() => {
    if (width < SMALL_MODE_WIDTH_BREAKPOINT && !isSmall) {
      setIsSmall(true);
    }
    else if (width >= SMALL_MODE_WIDTH_BREAKPOINT && isSmall) {
      setIsSmall(false)
    }
  }, [width, isSmall])

  const checkPathMatch = (path) => {
    if (location.pathname === path) {
      return true;
    }
    return false;
  }

  const renderNavLinks = () => {
    let navLinks = navLinksList;
    if (isSmall) {
      navLinks = [{url: '/', title: 'Home', exact: true}, ...navLinks]
    }

    return navLinks.map(({url, title, exact}) => {
      return (
        <li className='nav-item' key={url}>
          <NavLink className='nav-link' to={url} exact={exact}>{title}</NavLink>
        </li>
      )
    })
  }

  const renderNavButton = () => {
    const classNames = cx('navbar-button', {'active': checkPathMatch('/contact')})
    if (isSmall) {
      return (
      <NavLink className='nav-link' to='/contact'>
        Contact
      </NavLink>
      )
    }

    return (
      <NavLink className='nav-link' to='/contact'>
        <button className={classNames}>
          <div className='navbar-button-gradient-top'></div>
          <div className='navbar-button-gradient-bottom'></div>
        </button>
      </NavLink>
    )
  }

  const renderCollapsableContent = () => (
    <div className='collapse navbar-collapse' id='navbarSupportedContent'>
    <ul className='navbar-nav'>
      {renderNavLinks()}
      <li className='nav-item'>
        {renderNavButton()}
      </li>
    </ul>
  </div>
  )

  return (
    <nav className='navbar navbar-expand-lg navbar-light navbar-flex-container'>
      <Logo parentWidth={width}/>
      <button className='navbar-toggler' 
      type='button' 
      data-toggle='collapse' 
      data-target='#navbarSupportedContent' 
      aria-controls='navbarSupportedContent' 
      aria-expanded='false' 
      aria-label='Toggle navigation'>
        <span className='navbar-toggler-icon'/>
      </button>
      {renderCollapsableContent()}
    </nav>
  )
}

export default withResizeDetector(Navbar);
