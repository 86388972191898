import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';


export default function withThemeProvider(WrappedComponent) {

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: orange[500],
      }
    },
  });

  return class extends React.Component {

    render() {
      return (
      <ThemeProvider theme={theme}>
        <WrappedComponent {...this.props}/>
      </ThemeProvider>
      );
    }
  };
}

