import React from 'react';
import {Switch, Route} from 'react-router-dom';

import HomeView from '../../views/HomeView';
import ServicesView from '../../views/ServicesView';
import ProductsView from '../../views/ProductsView';
import IndustriesView from '../../views/IndustriesView';
import ContactView from '../../views/ContactView';

export default function Swithcer() {
  return (
    <Switch>
    <Route path='/' exact>
      <HomeView/>
    </Route>
    <Route path='/services'>
      <ServicesView/>
    </Route>
    <Route path='/products'>
      <div><ProductsView/></div>
    </Route>
    <Route path='/industries'>
      <IndustriesView/>
    </Route>
    <Route path='/about'>
      <div>About</div>
    </Route>
    <Route path='/contact'>
    <ContactView/>
    </Route>
  </Switch>
  )
} 

