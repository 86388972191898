import React from 'react'

import ContentContainer from '../components/ContentContainer/ContentContainer'

export default function HomeView() {
  const descriptionContent = (
    <>
      <p>
          The introduction of RFID technology in many industries in the
        recent past has significantly improved the process of
        inventory management and security for precious, dangerous
        and logically precious goods. Like any industry at its
        infancy, as its application within certain industries is
        examined, a new list of utility functions is created and new
        developments are spawned.
      </p>
      <p>
              The introduction of RFID technology in many industries in the
        recent past has significantly improved the process of
        inventory management and security for precious, dangerous
        and logically precious goods. Like any industry at its
        infancy, as its application within certain industries is
        examined, a new list of utility functions is created and new
        developments are spawned. 
      </p>
    </>
  )

  const img = <img src='https://imgur.com/rkN1Wuo.jpg' alt='Constant Tracker device' className='img-fluid'></img>

  const contentData = [
    {
      columnData: [
        {
          colWidth: 6,
          colContent: descriptionContent,
          colHeader: `Asset Management
          Tracking system using
          RFID Technology`
        },
        {
          colWidth: 6,
          img,
          layoutData: {
            crossAxisLayout: 'center'
          }
        }
      ],

    }
  ] 

  return <ContentContainer contentData={contentData} />
}
