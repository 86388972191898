import React from 'react'
import Button from '@material-ui/core/Button';
import './CustomButton.scss';
import withThemeProvider from '../../HOC/withThemeProvider';


const CustomButton = ({label, variant, color, fullWidth, children,
onClick}) => {
  return (
    <Button 
    label={label} 
    variant={variant} 
    color={color} 
    fullWidth={fullWidth}
    onClick={onClick}>
      {children}
    </Button>
  );
};

export default withThemeProvider(CustomButton);
