import React from 'react'

export const SMALL_MODE_WIDTH_BREAKPOINT = 943;
export const SMALL_LOGO_WIDTH_BREAKPOINT = 576;
export const SMALLEST_LOGO_WIDTH_BREAKPOINT = 330;

const thermaViewLogoLarge = <a href='http://thermaview.constant-tracker.com/'>
  <img 
  src='https://imgur.com/PqaEWNy.jpg' 
  alt='Therma View Logo'
  style={{width: '420px', position: 'relative', left: '-12px'}}
  className='img-fluid'/>
</a>

const thermaViewLogoSmall = <a href='http://thermaview.constant-tracker.com/'>
  <img 
  src='https://imgur.com/PqaEWNy.jpg' 
  alt='Therma View Logo'
  style={{width: '260px', position: 'relative', left: '-12px'}}
  className='img-fluid'/>
</a>

const constantTrackerLogoLarge = <img 
src='https://imgur.com/bx4JrRi.jpg' 
alt='Constant Tracker Logo'
style={{width: '420px'}}
className='img-fluid'/>

const constantTrackerLogoMedium = <img 
src='https://imgur.com/bx4JrRi.jpg' 
alt='Constant Tracker Logo'
style={{width: '300px'}}
className='img-fluid'/>

const constantTrackerLogoSmall = <img 
src='https://imgur.com/bx4JrRi.jpg' 
alt='Constant Tracker Logo'
style={{width: '200px'}}
className='img-fluid'/>

const constantTrackerLogoSmallest = <img 
src='https://imgur.com/bx4JrRi.jpg' 
alt='Constant Tracker Logo'
style={{width: '130px'}}
className='img-fluid'/>

export const thermaViewLogo = {
  LARGE: thermaViewLogoLarge,
  SMALL: thermaViewLogoSmall
}

export const constantTrackerLogo = {
  LARGE: constantTrackerLogoLarge,
  MEDIUM: constantTrackerLogoMedium,
  SMALL: constantTrackerLogoSmall,
  SMALLEST: constantTrackerLogoSmallest
}

export const TOAST_SEVERITY = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info'
};

export const TOAST_MESSAGE = {
  SENT: 'Message sent!',
  ERROR: 'Something went wrong, please try again later.'
}
