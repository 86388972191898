import React, {useState, useEffect} from 'react';
import { withResizeDetector } from 'react-resize-detector';
import cx from 'classnames';

import './ContentContainer.scss';
import {getFlexLayoutClasses} from './helpers';
import { Paper } from '@material-ui/core';

const WIDTH_BREAKPOINT = 600;

const ContentContainer = ({contentData, width, elevated}) => {
  const [isSmall, setIsSmall] = useState(false);

  const Header = ({children}) => {
    if (isSmall) {
      return (
      <h3 className='text-left'>
        {children}
      </h3>
      )
    }
    return (
    <h1 className='text-left'>
      {children}
    </h1>
    )
  }

  useEffect(() => {
    if (width < WIDTH_BREAKPOINT) {
      setIsSmall(true);
    }
    else if (width >= WIDTH_BREAKPOINT && isSmall) {
      setIsSmall(false)
    }
  }, [width, isSmall])

  const renderRows = () => {
    if (Array.isArray(contentData)) {
      return contentData.map(({columnData, rowGap, customRowStyles,}, index) => (
        <div className='row' key={index} style={{marginBottom: `${rowGap || 0}px`, ...customRowStyles}}>
          {renderColumns(columnData)}
        </div>
      ))
    }

    if (typeof contentData === 'object' && contentData !== null) {
      return (
      <div className='row'>
        {renderColumns(contentData)}
      </div>
      )
    }
  }

  const renderColumns = (data) => {
    if (Array.isArray(data)) {
      return data.map((singleColumnData, index) => {
        return renderSingleColumn(singleColumnData, index)
      })
    }

    if (typeof data === 'object' && data !== null) {
      return renderSingleColumn(data)
    }
  }

  const renderSingleColumn = (singleColumnData, index) => {
    const {colWidth, img, colHeader, colContent} = singleColumnData;
    let flexLayoutClasses = null;

    if (singleColumnData.layoutData) {
      const {layoutData} = singleColumnData
      flexLayoutClasses = getFlexLayoutClasses(layoutData)
    }

    if (img && !colContent && !colHeader) {
      const classNames = cx('d-flex justify-content-center align-items-start', isSmall ? 'col-12' : `col-${colWidth}`, flexLayoutClasses)
      return (
        <div className={classNames} key={index}>
        {renderImage(img)}
      </div>
      )
    }

    const classNames = cx(isSmall ? 'col-12' : `col-${colWidth}`, flexLayoutClasses)

    if (colHeader && !colContent && !img) {
      return (
        <div className={classNames} key={index}>
          {colHeader && <Header>{colHeader}</Header>}
          <br></br>
        </div>
      )
    }
    
    return (
      <div className={classNames} key={index}>
        {colHeader && <Header>{colHeader}</Header>}
        {renderImage(img)}
        {colContent}
      </div>
    )
  }

  const renderImage = (img) => {
    if (img?.SMALL && img?.LARGE) {
      if (isSmall) {
        return img.SMALL
      }
      return img.LARGE
    }
    if (img) {
      return img;
    }
  }

  const renderContentContainer = () => {
    if (elevated) {
      return (
        <div className='d-flex flex-column container'>
          <div className='content-container fade'>
            <Paper elevation={4}>
              {renderRows()}
            </Paper>
        </div>
      </div>
      )
    }
    return (
      <div className='d-flex flex-column container'>
        <div className='content-container fade'>
          {renderRows()}
      </div>
    </div>
    )
  }

  return renderContentContainer()
}

export default withResizeDetector(ContentContainer)
