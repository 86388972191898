import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const HIDE_DURATION = 4000;

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export default function Toast({message, open, severity, onClose, setOpen}) {

  return (
    <Snackbar open={open} autoHideDuration={HIDE_DURATION} onClose={onClose}>
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
