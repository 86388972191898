import React from 'react'
import ContentContainer from '../components/ContentContainer/ContentContainer'
import {thermaViewLogo} from '../utils/constants';

export default function ProductsView() {

  const thermaViewContent = (
    <div className='pb-3'>
      <p>
        ThermaView© is a stand alone device equipped with an
      optical camera for face and mask recognition, thermal
      imaging camera for contactless body temperature
      measurement, microphone and speaker for two way
      verbal communication to a desktop application.
      </p>
      <p>
        ThermaView© communicates with the user through a color
      touchscreen and connects to the desktop application through
      WIFI no cabling required. The desktop application also
      has the capability to record all activities in real time for
      safekeeping. 
      </p>
    </div>
  )

  const thermaViewImg = <img 
    src='https://imgur.com/3GJX0jH.jpg'
    alt='Therma View Device'
    style={{width: '70%'}}>
  </img>

  const womanWithThermaViewImg = <img 
    src='https://imgur.com/6tPYpSK.jpg' 
    alt='A woman with Therma View' 
    style={{width: '280px'}}>
  </img>

  const contentData = [
    {
      columnData: 
        {
          colWidth: 12,
          colHeader: 'Products'
        }
    },
    {
      columnData: [
        {
          colWidth: 4,
          img: womanWithThermaViewImg,
          layoutData: {
            crossAxisLayout: 'start'
          }
        },
        {
          colWidth: 4,
          colContent: thermaViewContent,
          img: thermaViewLogo
        },
        {
          colWidth: 4,
          img: thermaViewImg,
          layoutData: {
            crossAxisLayout: 'center'
          }
        }
      ]
    }
  ]

  return <ContentContainer contentData={contentData}/>
}
