import React from 'react'
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

export default function CustomTextField({value, label, variant, multiline, rows, error, helperText,
   onChange, onBlur}) {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: orange[500],
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <TextField
      value={value}
      label={label} 
      variant={variant} 
      multiline={multiline} 
      rows={rows}
      fullWidth
      error={error}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur} />
    </ThemeProvider>
  )
}
