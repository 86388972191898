import React from 'react'
import './Footer.scss'

const Footer = () => {
  return (
    <div className='footer'>
      COPYRIGHT© 2021 - Constant Tracker, Inc. - All Rights Reserved Worldwide.
    </div>
  )
}

export default Footer;
