import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import withThemeProvider from '../../HOC/withThemeProvider';

const Spinner = () => {

  return (
    <CircularProgress color='primary' />
  );
};

export default withThemeProvider(Spinner);
