import React from 'react'
import {NavLink} from 'react-router-dom'

import {constantTrackerLogo, SMALL_LOGO_WIDTH_BREAKPOINT,
  SMALLEST_LOGO_WIDTH_BREAKPOINT} from '../../utils/constants'
  import './Logo.scss'

const thermaViewLogoSmall = <a href='http://thermaview.constant-tracker.com/'>
  <img 
  src='https://imgur.com/PqaEWNy.jpg' 
  alt='Therma View Logo'
  style={{width: '180px'}}
  className='img-fluid'/>
</a>

const thermaViewLogoSmallest = <a href='http://thermaview.constant-tracker.com/'>
  <img 
  src='https://imgur.com/PqaEWNy.jpg' 
  alt='Therma View Logo'
  style={{width: '110px'}}
  className='img-fluid'/>
</a>

const thermaViewLogo = {
  SMALL: thermaViewLogoSmall,
  SMALLEST: thermaViewLogoSmallest
}

const Logo = ({parentWidth}) => {

  const renderConstantTrackerLogo = () => {
    if (parentWidth < SMALLEST_LOGO_WIDTH_BREAKPOINT) {
      return <NavLink to='/' exact>{constantTrackerLogo.SMALLEST}</NavLink>;
    }
    if (parentWidth < SMALL_LOGO_WIDTH_BREAKPOINT) {
      return <NavLink to='/' exact>{constantTrackerLogo.SMALL}</NavLink>;
    }
    return <NavLink to='/' exact>{constantTrackerLogo.MEDIUM}</NavLink>;
  }

  const renderThermaViewLogoContent = (size) => {
    return (
    <div className='logo-content-container'>
      <span className='logo-text'>Home of</span>
      {thermaViewLogo[size]}
    </div>
    )
  }

  const renderThermaViewLogo = () => {
    if (parentWidth < SMALLEST_LOGO_WIDTH_BREAKPOINT) {
      return renderThermaViewLogoContent('SMALLEST')
    }
    return renderThermaViewLogoContent('SMALL')
  }

  return (
    <div className='logo-container'>
      {renderConstantTrackerLogo()}
      {renderThermaViewLogo()}
    </div>
  )
}

export default Logo;
